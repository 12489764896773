import Photo from './photo';
import Link from 'next/link';
export default function HeroSection(props) {
    return (
        <section>
            <div className={`hero-section relative  ${props.headerCss ? props.headerCss : ''}`}>
                <div className="relative w-full flex">
                    {props.image?.data ? (
                        props.imageAsBackground ? (
                            <>
                                <div
                                    className={`hero-section-bg absolute z-1 top-0 w-full h-full bg-no-repeat bg-cover bg-opacity-20  bg-center `}
                                    style={{
                                        backgroundImage: `url(${props.image.data.attributes.url})`
                                    }}
                                ></div>
                                <div className="container flex relative z-2 ">
                                    <div className="hero-section-content">
                                        <div
                                            className={` flex flex-col text-white justify-start`}
                                            dangerouslySetInnerHTML={{ __html: props.content }}
                                        ></div>
                                        {props.buttons.length > 0 && (
                                            <div className="mt-4">
                                                {props.buttons.map((btn) => (
                                                    <Link key={btn.id.toString()} href={btn.link}>
                                                        <a target={btn.target} className={`btn btn-lg mr-2 ` + btn.style}>
                                                            {btn.label}
                                                        </a>
                                                    </Link>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="container">
                                <div className="flex items-center justify-start py-[10rem]">
                                    <div className="flex w-2/5 mx-auto md:w-1/2 relative z-20 pl-8 justify-center">
                                        <Photo image={props.image}></Photo>
                                    </div>
                                    <div
                                        className="flex flex-col w-full p-8 pt-0 md:w-2/3 text-white justify-start"
                                        dangerouslySetInnerHTML={{ __html: props.content }}
                                    ></div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="container">
                            <div className="flex items-center justify-start pt-[6rem] pb-[6rem]">
                                <div
                                    className={`flex flex-col w-full p-10 ${!props.full ? 'md:w-2/3' : ''
                                        } text-white justify-start`}
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </section>
    );
}
